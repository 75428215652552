import Route from 'mewe/routes/route';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import Session from 'mewe/shared/session';
import Storage from 'mewe/shared/storage';
import { PUBLIC_ENTITY_TYPE } from 'mewe/constants';
import { fetchMeweOpenFeed, getMeweOpenFeed } from 'mewe/fetchers/fetch-feed';
import { isMeweTeamUnauthorized } from 'mewe/helpers/is-mewe-team';

export default class PublicFeedRoute extends Route {
  @service analytics;

  beforeModel() {
    Session.isAuthenticated().then(({ isAuthenticated }) => {
      // for logged in user open the post in application
      if (isAuthenticated) {
        window.location.href = '/'; // do not transitionTo - there is a problem with such redirection from outside of the app to the app
      } else {
        // additional easy way to force open feed homepage with URL hash:
        // reset current 'indexRedirectTo' assignment and skip checking isMeweTeamUnauthorized
        if (window.location.hash === '#openFeed') {
          Storage.set(Storage.keys.indexRedirectTo, null);
        } else {
          // For now A/B tests open feed is disabled for non-mewe users,
          // in order to test it internally first
          // if (!isMeweTeamUnauthorized()) {
          //   return this.transitionTo('register');
          // }
        }

        if (JSON.parse(Storage.get(Storage.keys.indexRedirectTo)) === 'register') {
          return this.transitionTo('register');
        }

        // if there is no indexRedirectTo set, then it's the first time the user is
        // opening experiment, so only then we want to send the event
        if (JSON.parse(Storage.get(Storage.keys.indexRedirectTo)) !== 'homefeed') {
          Storage.set(Storage.keys.indexRedirectTo, JSON.stringify('homefeed'));
        }

        this.analytics.sendEvent('pageViewed', '/homefeed');

        let locale = 'en';
        const user = JSON.parse(Storage.get(Storage.keys.currentUser));
        if (user?.locale) {
          locale = user.locale;
        }

        fetchMeweOpenFeed({ limit: 20, language: locale });
      }
    });
  }

  model() {
    return EmberObject.create({
      feed: getMeweOpenFeed(),
      entityType: PUBLIC_ENTITY_TYPE.FEED,
    });
  }
}