import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';
import Storage from 'mewe/shared/storage';
import { keys } from 'mewe/shared/keys';
import tokenManager from 'mewe/shared/token-manager';
import PublicPagesApi from 'mewe/api/public-pages-api-unauth';
import CurrentUserStore from 'mewe/stores/current-user-store';
import { isInJail, isLocked, isDsnpUserWithoutName } from 'mewe/utils/jail-utils';
import { publicIdEmailParams } from 'mewe/constants';
import FunctionalUtils from 'mewe/shared/functional-utils';
import { getQueryStringParams, getNextFromUrl } from 'mewe/shared/utils';
import MiscellaneousUtils from 'mewe/utils/miscellaneous-utils';
import config from 'mewe/config';

export default class AuthenticationService extends Service {
  @service router;
  @service analytics;

  @tracked loginWindow;

  registrationCallback(registrationParams, registrationResponse, urlParams) {
    const params = registrationParams;
    const user = registrationResponse.user;

    Storage.markRegistration();
    Storage.remove(keys.smsSent);

    if (!user.locale && params.locale) {
      user.locale = params.locale;
    }

    tokenManager.set({ user: user });

    // redirection is delayed to make sure that token manager finished its job
    later(this, () => this.redirectAfterRegistration(registrationParams, user, urlParams), 1000);
  }

  redirectAfterRegistration(registrationParams = {}, user = {}, urlParams = {}) {
    // dsnpSignup sent in SMSv1, sessionId sent in SMSv2/v3 and email registration. Otherwise it's Mewe registration
    const isDsnpMode = registrationParams.sessionId || registrationParams.dsnpSignup;
    const isSiwaMode = registrationParams.siwaMode || urlParams.authorizationCode;

    urlParams.hash = '#new-user';

    if (isSiwaMode) {
      // DSNP users are granted with dark-theme and it should be turned on by default
      Storage.set(Storage.keys.desktopSettings, JSON.stringify({ activeTheme: 'dark' }));
      // this will communicate accross windows in case of authentication from separate window
      Storage.set(Storage.keys.siwaLoginRedirect, JSON.stringify(urlParams || { next: '/myworld' }));
      Storage.remove(Storage.keys.siwaLoginRedirect);

      return this.doRedirect(urlParams);
    }

    // in DSNP flow we end up here only after SMS regisration and use is already confirmed/authenticated.
    // (email registration with DSNP ends up on email confirmation link page)
    if (isDsnpMode) {
      // DSNP users are granted with dark-theme and it should be turned on by default
      Storage.set(Storage.keys.desktopSettings, JSON.stringify({ activeTheme: 'dark' }));
      return this.doRedirect(urlParams);
    }

    // in old MeWe flow user has to confirm his email first by clicking on the link in email
    if (!isDsnpMode && !isSiwaMode) {
      Storage.set(Storage.keys.ftueUnconfirmedUser, JSON.stringify(user));
      this.router.transitionTo('welcome');
    }
  }

  loginCallback(loginParams, loginResponse, urlParams, migrationLoginCallback, isSiwaFlow) {
    const user = loginResponse.user;
    const isEmailFlow = ~loginParams.username?.indexOf('@');
    const isMigrationPage = !!migrationLoginCallback; // migration has speciall callback, other logins continue to app

    tokenManager.set(loginResponse, false);
    CurrentUserStore.send('handle', user, true);

    if (isDsnpUserWithoutName(user)) {
      // Signup flow - new user without name created with Frequency
      // do not redirect because name/handle claiming screen is displayed in MwSiwaSignin
      return;
    }

    this.analytics.sendEvent('loggedIn', {
      // `is_web3` is superproperty set before sending each event in the service,
      // but for `loggedIn` it has to be set here because service won't know yet if user is DSNP
      is_web3: !!loginResponse.dsnpDetails || isSiwaFlow,
      is_web3_migration: isMigrationPage,
      login_type: isSiwaFlow ? 'siwa' : (isEmailFlow ? 'email' : 'phone'),
    });

    // postponed to allow analytics to record events before reload,
    // this can be removed once we redirect without reloading page
    later(
      this,
      () => {
        CurrentUserStore.getState().deferred.promise.then(() => {
          // this will communicate accross windows in case of authentication from separate window
          Storage.set(Storage.keys.siwaLoginRedirect, JSON.stringify(urlParams || { next: '/myworld' }));
          Storage.remove(Storage.keys.siwaLoginRedirect);

          if (isInJail(loginResponse)) {
            this.router.transitionTo('lockout');
          } else if (isLocked(loginResponse)) {
            this.router.transitionTo('verify');
          } else {
            // - after login in migration flow go to handle claiming step instead of redirecting to app
            if (isMigrationPage) {
              migrationLoginCallback();
            } else {
              this.redirectAfterLogin(urlParams);
            }
          }
        });
      },
      1000
    );
  }

  redirectAfterLogin(urlParams = {}) {
    if (urlParams.next) {
      // use substring of href if possible instead of urlParams.next to avoid loosing query params from next url
      const nextFromUrl = getNextFromUrl();

      if (nextFromUrl) {
        window.location.href = nextFromUrl;
      } else {
        window.location.href = urlParams.next;
      }

      if (urlParams.next.indexOf('?download') !== -1) {
        window.setTimeout(() => (window.location.href = '/myworld'), 3500);
      }
    } else {
      this.doRedirect(urlParams);
    }
  }

  doRedirect(urlParams) {
    const hash = urlParams?.hash || '';

    // case for registration by email
    let cmpgn = urlParams?.cmpgn || '';

    // case for registration by sms
    let urlObj = new URL(window.location.href);
    // in future we can change it to more generic campaign parameter
    if (urlObj.searchParams.has('brave')) {
      cmpgn = 'brave';
    }

    if (urlParams) {
      // profile/page redirection - url is simply `/{publicId}` in both cases
      if (urlParams.ppId || urlParams.upId) {
        return (window.location = `/${urlParams.ppId || urlParams.upId}${hash}`);
      }

      if (urlParams.epId) {
        // event redirection - if there is `id-` prefix indicating actual eventId then
        // we go straight to event, otherwise it's publicId and we use it to fetch event public data to get eventId.
        const nonPublicId = ~urlParams.epId.indexOf('id-') ? urlParams.epId.replace('id-', '') : null;

        if (nonPublicId) {
          return (window.location = `/event/${nonPublicId}${hash}`);
        } else {
          return PublicPagesApi.getEvent(urlParams.epId).then(
            (data) => (window.location = `/event/${data.event.id}${hash}`)
          );
        }
      }

      if (urlParams.gpId) {
        // group redirection - if there is `id-` prefix indicating actual groupId then we go straight to group,
        // otherwise it's publicId and we use it to fetch group public data to get groupId.
        // - usecase is when we already checked on public page that user can go straigh to group, no need to repeat that.
        // - other usecase is private group invitation where we don't have publicId in the process
        const nonPublicId = ~urlParams.gpId.indexOf('id-') ? urlParams.gpId.replace('id-', '') : null;

        if (nonPublicId) {
          return (window.location = `/group/${nonPublicId}${hash}`);
        } else {
          return PublicPagesApi.group(urlParams.gpId).then((data) => {
            if (data.isMember || data.isInvited) {
              return (window.location = `/group/${data.id}${hash}`);
            } else {
              return (window.location = `/myworld?previewGroupId=${data.id}${hash}`);
            }
          });
        }
      }
    }

    // Campaign happens only with no other params (no group/page/event/user invitation)
    if (cmpgn) {
      return (window.location = `/myworld?${cmpgn}${hash}`);
    }

    // default redirection
    let defaultRedirectionParams = '';
    // deleted identifiers because of SIWA migration
    if (urlParams.removedCredentials) {
      defaultRedirectionParams = '?removedCredentials=true';
    }
    return (window.location = `/myworld${defaultRedirectionParams}${hash}`);
  }

  startSiwa(finallyCallback, inSparateWindow) {
    const params = {};
    const urlParams = getQueryStringParams();

    this.analytics.sendEvent('buttonClicked', 'Login with Frequency');

    // passing origin of registration flow, later it will be used for redirection after confirming email.
    // in sms flow it's not needed because user is redirected from current component so it will be checked here.
    publicIdEmailParams.forEach((param) => {
      if (urlParams?.[param]) {
        params[param] = urlParams[param];
      }
    });

    if (urlParams.next) {
      params.next = getNextFromUrl() || urlParams.next;
      // SIWA callback url will contain more params after the `next` param (..&next=/foo&authCode=xxx..)
      // so in order to later extract `next` url without loosing it's params we replace `&` and `?` characters with `+`.
      // When SIWA will redirect to our app siwa/signin route and we'll extract `next` param we will also revert that change.
      // (SIWA encodes callback url so this solution was not used to avoid double encoding and potential issues)
      params.next = params.next.replace(/[&?]/g, '+');
    }

    PublicPagesApi.getSiwa(params)
      .then((res) => {
        if (config.environment != 'prod') {
          const getSmsTokenApi = res.location.replace('siwa/start', 'testing/siwa/token');
          MiscellaneousUtils.copyToClipboard(getSmsTokenApi, true);
        }

        if (inSparateWindow) {
          const windowWidth = Math.min(600, window.innerWidth);
          const windowHeight = 720;
          const valueLeft = window.screenX + (window.innerWidth - windowWidth) / 2;
          const valueTop = window.screenY + 50;
          const options = 'left=' + valueLeft + ',top=' + valueTop + ',width=' + windowWidth + ',height=' + windowHeight;

          // Only open window in response to user interaction
          // Idea dropped for now as it reqires more time to test properly
          // if (document.hasFocus()) {
          //   this.loginWindow = window.open(res.location, '_blank', options);
          // }

          if (!this.loginWindow) {
            return window.location = res.location;
            // FunctionalUtils.error(__('Please disable the Pop-up blocker in your browser.'));
          } else {
            this.loginResultHandler = (ev) => {
              let newValue = ev && ev.key === Storage.keys.siwaLoginRedirect && ev.newValue;
              if (!newValue) return;

              try {
                newValue = JSON.parse(newValue);
              } catch (e) {}

              const urlParams = newValue ? newValue.urlParams : '';

              window.removeEventListener('storage', this.loginResultHandler);

              if (this.loginWindow) {
                this.loginWindow.close();
                this.redirectAfterLogin(urlParams);
              }
            };

            try {
              // remove listener first in case of paypal popup being opened more than once, listeners would be duplicated
              window.removeEventListener('storage', this.loginResultHandler);
              window.addEventListener('storage', this.loginResultHandler);
            } catch (e) {
              console.error(e);
            }
          }
        } else {
          window.location = res.location;
        }
      })
      .catch(() => {
        FunctionalUtils.showDefaultErrorMessage();
      })
      .finally(() => {
        finallyCallback?.();
      });
  }
}
