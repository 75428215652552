import AuthClass from '../../home/mw-home-auth-class';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import dispatcher from 'mewe/dispatcher';
import { StickyController } from 'mewe/utils/elements-utils';
import { iosAppUrl, androidAppUrl } from 'mewe/constants';
import Storage from 'mewe/shared/storage';

export default class AppHomefeed extends AuthClass {
  iosAppUrlConst = iosAppUrl;
  androidAppUrlConst = androidAppUrl;

  @service router;
  @service authentication;
  @service analytics;
  @service dynamicDialogs;
  @tracked isSidebarMenuVisible = false;
  @tracked isRedirectingSiwa;

  get isSiwaEnabled() {
    return this.configLoaded && this.authMode === 'dsnp_siwa';
  }

  get showLoginButton() {
    return this.configLoaded && (this.authMode !== 'dsnp_siwa' || this.authPass === true);
  }

  get isStartSiwaDisabled() {
    return !this.configLoaded || this.isRedirectingSiwa;
  }

  @action
  startSiwa() {
    this.isRedirectingSiwa = true;

    const startSiwaCallback = () => {
      this.isRedirectingSiwa = false;
    };

    this.authentication.startSiwa(startSiwaCallback, true);
  }

  @action
  goToPasswordLogin() {
    const queryParams = {};
    if (this.isSiwaEnabled) {
      queryParams.fromHomefeed = true;
    }
    this.analytics.sendEvent('buttonClicked', 'Login with Password');
    this.router.transitionTo('login', { queryParams });
  }

  @action
  goToSignUp() {
    Storage.remove(Storage.keys.indexRedirectTo);
    this.router.transitionTo('register');
  }

  @action
  goToLogin() {
    this.router.transitionTo('login');
  }

  @action
  onSidebarInsert(element) {
    this.stickyController = new StickyController({ element: element });
  }

  @action
  showSignin() {
    this.analytics.sendEvent('buttonClicked', 'Sign in in Open Feed');
    this.dynamicDialogs.openDialog('open-feed-login-dialog');
  }

  @action
  changeLocale(locale) {
    dispatcher.dispatch('client-data', 'setLocale', locale, true);
  }

  @action
  headerMenuClick() {
    this.isSidebarMenuVisible = !this.isSidebarMenuVisible;
    document.body.style.overflow = this.isSidebarMenuVisible ? 'hidden' : 'auto';
  }

}