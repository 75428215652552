module.exports = {
  "apiRoot": "/api/v2",
  "apiRoot3": "/api/v3",
  "apiCacheRoot": "/api/cache/v2",
  "buildNumber": "4.170.0",
  "giphyApiKey": "S4tCV9IArCPwyUMj2a9r6DDq5sdWsigi",
  "mixpanelApiKey": "e73ceacc7559b4f5733af6581937d051",
  "mixpanelApiHost": "https://mp.mewe.com",
  "paypalClientId": "ATvdkBCQJBIvDqO9a_fYuCLHZddmwpUbubizSdum41KWRb7foaQRYi3W96Ww",
  "maxTextLimit": "63999",
  "maxMessageLimit": "8192",
  "maxFileLimit": "500",
  "chatMaxUsers": "100",
  "maxContacts": "8000",
  "maxFavorites": "PLACEHOLDER_MAX_FAVORITES",
  "cdnHost": "https://cdn.mewe.com",
  "distDir": "target/frontend",
  "sseHost": "https://ws.mewe.com",
  "arkoseKeyReg": "E0CD0DF3-E88E-BE5A-21D6-DC4F0C9ABC1C",
  "arkoseKeyLog": "23C24999-3098-7CBD-4B1C-31492C428E74",
  "apiHost": "https://mewe.com",
  "websocketsHost": "wss://ws.mewe.com",
  "cdnEmojiHost": "https://cdn.mewe.com",
  "cHost": "https://mewe.com",
  "imgHost": "https://img.mewe.com",
  "publicImgHost": "https://public-img.mewe.com",
  "matomo": "PLACEHOLDER_MATOMO",
  "configCatSDKKey": "sBi_zfmb80W_av-a3sS2kg",
  "sentryDSN": "https://206f611c634e48e88ca08879ffd76cc2@o62418.ingest.sentry.io/6504568",
  "sampleRate": "0.5",
  "tracesSampleRate": "0.05",
  "environment": "prod",
  "hcaptcha": {
    "lock": "c64bea5f-d8a5-4c0c-bf81-2bde11805c9b",
    "registration": "0acf06ee-87ca-460e-bf16-53125d997808",
    "login": "884185af-836c-447c-815e-b83f326f569c",
    "password": "fc7d8a3d-1c86-4de7-8901-e34b5db61e22",
    "phoneValidation": "187e46cd-0e34-430a-975f-a7135bb09015"
  }
}
