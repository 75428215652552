import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class ProfileLink extends Component {
  @service dynamicDialogs;
  @service dynamicPopups;
  @service analytics;

  /** @computed */
  @computed('args.groupId')
  get isGroup() {
    return this.args.groupId && this.args.groupId !== 'contacts' && this.args.groupId !== 'privacymail';
  }

  @computed('args.{postedByPage,pageUrlId}')
  get isPage() {
    return this.args.postedByPage && this.args.pageUrlId;
  }

  // userId is profileId
  // uId is always user.id, legacy stuff
  get getRoute() {
    if (this.args.eventId) {
      return this.args.openSubProfile === false ? 'app.publicid' : 'app.event.attendees.profile';
    } else if (this.isGroup) {
      return this.args.openSubProfile === false ? 'app.publicid' : 'app.group.members.profile';
    } else if (this.isPage) {
      // SG-25555
      return 'app.publicid.feed';
    } else {
      return 'app.publicid.posts';
    }
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('{isPage,isGroup}', 'args.{groupId,userId,eventId,openSubProfile,uId,pageUrlId}')
  get getModels() {
    if (this.args.eventId) {
      return this.args.openSubProfile === false
        ? [this.args.userId]
        : [this.args.eventId, this.args.uId || this.args.userId];
    } else if (this.isGroup) {
      return this.args.openSubProfile === false
        ? [this.args.userId]
        : [this.args.groupId, this.args.uId || this.args.userId];
    } // for group profile, user.id as uId has preference
    else if (this.isPage) {
      return [this.args.pageUrlId];
    } else {
      return [this.args.userId];
    }
  }

  /** @actions */
  @action
  onClick() {
    if (this.args.post?.isGroupPreview) return;

    // isPublicViewPost is an exception from customInteraction popup -
    // we allow redirecting to profile/page from a public post
    if (!this.args.post?.customInteraction || this.args.post?.isPublicViewPost) {
      // postponed actions binded to link click
      // otherwise any action here cause page reload
      next(() => this.dynamicDialogs.closeAll());
    }
  }
}
