import Component from '@glimmer/component';
import { A } from '@ember/array';
import { set } from '@ember/object';
import { each } from 'lodash';
import { task } from 'ember-concurrency';

import DocumentsApi from 'mewe/api/documents-api';

export default class MwFolder extends Component {

  @task({ drop: true })
  * selectFolder(folder) {
    let options = {
      foldersOnly: true,
      sortDesc: true,
    };

    each(this.args.nodes, (n) => {
      set(n, 'folder', A());
    });

    this.args.setTargetNodeId?.(folder.id);
    this.args.folderSelected();

    const callback = (data) => {
      //remove folder itself, you cannot move folder to itself or to its children (dead loop)
      data.list = data.list.filter((f) => this.args.currentDocumentId !== f.id);
      each(data.list, (el) => (el.folder = A()));

      folder.folder.pushObjects(data.list);
    };

    if (this.args.targetNodeId !== 'root' && !folder.folder.length) {
      if (folder) {
        set(folder, 'selected', true);
      } else {
        folder = {
          id: 'root',
        };
      }

      yield DocumentsApi.getFolderTree(folder.id, options).then((data) => callback(data));
    }
  }
}
